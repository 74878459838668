import { Box, Flex, Grid, GridItem, Image, useMediaQuery } from "@chakra-ui/react"
import mobileInstructions from '../../assets/images/instructions/mobile-instructions-frame.png'
import one from '../../assets/images/instructions/1.png'
import two from '../../assets/images/instructions/2.jpg'
import three from '../../assets/images/instructions/3.png'

const InstructionCard = ({ Heading, text }) => {
    const [matchTablet] = useMediaQuery(["(max-width: 768px)"])
    const [matchMobile] = useMediaQuery(["(max-width: 425px)"])

    return (
        <Box id="instruction-container" position={'sticky'} top={'0'} zIndex={5}>
            {matchTablet ? (
                <Box p='10px' paddingInline={'1rem'}>
                    <Flex justifyContent={'space-between'}>
                        <Box>
                            {Heading}
                        </Box>
                        <Box w='fit-content' textAlign={'-webkit-right'} position={'relative'}>
                            <Image maxH='575px' h={'80%'} src={mobileInstructions} alt="" />
                            <Flex id='tablet-image-instruction' flexDirection={'column'} zIndex={-1} w={'76.4%'} h={'77%'} borderRadius={'30px'} overflow={'hidden'} right={'0px'} top='10px' position={'absolute'}>
                                <Image w='-webkit-fit-content' h='100%' src={one} alt="" />
                                <Image w='-webkit-fit-content' h='100%' src={two} alt="" />
                                <Image w='-webkit-fit-content' h='100%' src={three} alt="" />
                            </Flex>
                        </Box>

                    </Flex>
                    <Box mt={matchMobile ? '3rem' : '6rem'}>
                        {text}
                    </Box>
                </Box>
            )
                : (
                    <Grid templateAreas={matchTablet ?
                        `"install . mobile"
                        "install . mobile"
                        "paragraph paragraph paragraph"`
                        :
                        matchMobile ?
                            `"install . mobile"
                            "install . mobile"
                            "paragraph paragraph paragraph"`
                            :
                            `"install . mobile"
                            "install paragraph mobile"`}
                        templateColumns={['200px 1fr 282px','200px 1fr 282px','200px 1fr 282px','200px 1fr 282px','200px 1fr 340px']}
                        templateRows={matchTablet ? '200px 200px 282px' : ''}
                        pt={['3rem', '3rem', '4rem', '6rem', '7rem']} paddingLeft={['1rem', '2rem', '4rem', '6rem', '9rem']} paddingRight='5rem'>
                        <GridItem alignSelf={'flex-end'} area={'install'} gap='20px'>
                            {Heading}
                        </GridItem>
                        <GridItem area={'mobile'} textAlign={'end'} position='relative' >
                            <Image w='282px' h='575px' position={'absolute'} src={mobileInstructions} alt="" />
                            <Flex id='image-instruction' flexDirection={'column'} zIndex={-1} w={'255px'} h='552px' borderRadius={'25px'} overflow={'hidden'} right={['14px','14px','14px','14px','71.5px']} top='11px' position={'absolute'}>
                                <Image w='255px' h='100%' src={one} alt="" />
                                <Image w='255px' h='100%' src={two} alt="" />
                                <Image w='255px' h='100%' src={three} alt="" />
                            </Flex>
                        </GridItem>
                        <GridItem area={'paragraph'} alignSelf={'flex-end'} >
                            {text}
                        </GridItem>
                    </Grid>
                )
            }
        </Box >
    )
}
export default InstructionCard;