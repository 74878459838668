import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Image, Input, Text, useMediaQuery, useToast } from "@chakra-ui/react";
import sekkaraPurpleLogo from '../../assets/vectors/sekkara-purple-logo.svg'
import location from '../../assets/vectors/contactus/location.svg'
import Footer from "../layouts/Footer";
import facebook from '../../assets/vectors/media/facebook.svg'
import instagram from '../../assets/vectors/media/instagram.svg'
import logo from '../../assets/vectors/sekkara-logo.svg'
import mapPin from '../../assets/vectors/contactus/map-pin.svg'
import phone from '../../assets/vectors/contactus/phone.svg'
import { useRef, useState } from "react";
import emailjs from 'emailjs-com'
// import leftLine from '../../assets/vectors/contactus/Line 1.svg';
// import rightLine from '../../assets/vectors/contactus/Line 2.svg';
import '../../styles/contactus.css'
const ContactUs = () => {
    const [matchTablet] = useMediaQuery(["(max-width: 768px)"])
    const [matchMobile] = useMediaQuery(["(max-width: 570px)"])
    const [matchLaptop] = useMediaQuery(["(max-width: 1004px)"])
    const [form,setForm] = useState({name:'',message:'',email:''})
    const [formError,setFormError] = useState({name:'',message:'',email:''})
    const [loading,setLoading] = useState(false)
    
    const HtmlForm = useRef();
    const toast = useToast()
    const showSuccessToast = () => {
        toast({
            title: "Submitted Successfully",
            description: 'Message have been sent.',
            status: 'success',
            duration: 9000,
            isClosable: true,
        })
    }
    const showErrorToast = () => {
        toast({
            title: 'Something went wrong, try agin later.',
            duration: 9000,
            isClosable: true,
        })
    }
    const handleChange = (event)=>{
        if (event && event?.target) {
            const { value, name } = event?.target;
            if(value==='') setFormError({...formError,[name]:true})
        setForm({ ...form, [name]: value });
    }
    }
    const submit =()=>{
        setLoading(true);
        if(form.name!=='' && form.email!=='' && form.message!==''){    
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, HtmlForm.current, process.env.REACT_APP_PUBLIC_KEY)
        .then(result => {
          showSuccessToast();
        }).catch(error=>{
            showErrorToast();
        }).finally(()=>setLoading(false))
        }
        else{
            setLoading(false)
            setFormError({email:form.email===''?true:false,message:form.message===''?true:false,name:form.name===''?true:false})
        }
    }
    return (
        <Flex justifyContent={'center'} flexDirection={'column'} id='contactus' bg='white' position={'absolute'} zIndex={100} w='100%' h={['120vh','100vh','100vh','100vh','100vh']}>
            <Flex alignItems={'center'} justifyContent='center' pt='2rem' paddingInline={matchMobile ? '10px' : '40px'}>
                
                <Box bg='#F7F6F6' h='auto' w='100%' border={matchTablet ? '4px solid black' : '8px solid black'} borderRadius='25px' position='relative'>
                   {/* <Box className="dotted-spaced-left" transform={'rotate(25deg)'}> .</Box>
                   <Box className="dotted-spaced-right" transform={'rotate(-25deg)'}> .</Box> */}

                    {!matchTablet &&
                        <>
                            <Box position={'absolute'} top={'88%'} right='4%'>
                                <Flex justifyContent={'flex-end'} alignItems={'center'} gap='7px'> <Image src={mapPin} alt='' /> <Text m='0' fontWeight={'400'} fontFamily={'Lato'} fontSize={'15px'}>AlBahsa, Damascus, Syria</Text></Flex>
                                <Flex gap='7px' justifyContent={'flex-end'} alignItems={'center'}> <Image src={phone} alt='' /> <Text m='7px 0' fontWeight={'400'} fontFamily={'Lato'} fontSize={'15px'}>+963 941 444 806</Text></Flex>
                            </Box>
                            <Flex position={'absolute'} zIndex={100} top={'94%'} left='4%' gap={'15px'}>
                                <a rel="noreferrer" target="_blank" href='https://www.facebook.com/profile.php?id=100089102088429&mibextid=ZbWKwL'><Image cursor={'pointer'} src={facebook} alt='' /></a>
                                <a rel="noreferrer" target="_blank" href='https://instagram.com/sekkara2023?igshid=YmMyMTA2M2Y='><Image cursor={'pointer'} src={instagram} alt='' /></a>
                            </Flex>
                        </>
                    }
                        <form ref={HtmlForm}>
                    <Flex alignItems={'center'} position={'relative'} flexDirection={matchTablet ? 'column' : ''} justifyContent={matchTablet ? 'center' : 'space-between'}>
                       

                        <Box position='relative' pt={matchTablet ? '1rem' : '3rem'} h={['450px', '550px', '100%', '100%', '100%']}>
                            <Flex flexDirection={'column'} zIndex={'100'} paddingInline={matchTablet ? '0px' : '4rem'} alignItems={matchTablet ? 'center' : ''} justifyContent={matchTablet ? 'center' : ''} position={matchTablet ? 'relative' : 'absolute'}>
                                <Image w='150px' h={matchTablet ? '25px' : '50px'} src={logo} alt='' />
                                <Flex mt={matchTablet ? '1rem' : '0rem'} paddingInline={matchMobile ? '2rem' : '0rem'} flexDirection={!matchTablet ? 'column' : 'row'} w={'fit-content'} ><Text textAlign={matchTablet ? 'center' : ''} m='0' fontWeight='700' fontFamily={'Albra Display TRIAL'} fontSize={matchTablet ? '25px' : matchLaptop ? '30px' : '40px'}>Making Customers Happier <span style={{ margin: '0', fontWeight: '700', fontSize: matchTablet ? '25px' : matchLaptop ? '35px' : '40px', fontFamily: 'Albra Display TRIAL', color: '#E6007E' }}>From</span></Text></Flex>
                            </Flex>
                            <Image mt={matchTablet ? '0px' : '50px'} objectFit={'cover'} position={'relative'} bottom={matchTablet ? '70px' : '0px'} w='700px' borderRadius={'25px'} h={matchMobile ? '400px' : '550px'} src={location} alt='' />
                        </Box>
                        <Flex  w={matchTablet ? '70%' : '30%'} position={'relative'} bottom={matchMobile ? '20px' : '0px'} pr={matchTablet ? '0rem' : '4rem'} flexDirection={'column'} alignItems={matchTablet ? 'center' : ''} gap={matchMobile ? '20px' : '50px'}>
                            <FormControl isRequired isInvalid={formError.name}>
                                <Input placeholder="Name" p='0' onChange={handleChange} required opacity={'0.7'} lineHeight='30px' outline={'none'} boxShadow={'none !important'} _focusVisible={{ borderColor: '#E6007E' }} fontSize={matchTablet ? '15px' : '20px'} color='#8D8D8D' bg='#F7F6F6' borderLeft={'none'} borderTop='none' borderRight={'none'} borderBottom={'5px solid #C8D5D1'} fontWeight='300' fontFamily={'Lato'} type='text' name='name' />
                            <FormErrorMessage>
                                {form.name===''?'name cannot be empty':''}
                            </FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formError.email}>
                                <Input placeholder="Email"  p='0' onChange={handleChange} opacity={'0.7'} lineHeight='30px' outline={'none'} boxShadow={'none !important'} _focusVisible={{ borderColor: '#E6007E' }} fontSize={matchTablet ? '15px' : '20px'} color='#8D8D8D' bg='#F7F6F6' borderLeft={'none'} borderTop='none' borderRight={'none'} borderBottom={'5px solid #C8D5D1'} fontWeight='300' fontFamily={'Lato'} type='email' name='email' />
                                <FormErrorMessage>
                                {form.email===''?'email cannot be empty':''}
                            </FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formError.message}>
                                <Input placeholder="Message"  p='0' onChange={handleChange} opacity={'0.7'} lineHeight='30px' outline={'none'} boxShadow={'none !important'} _focusVisible={{ borderColor: '#E6007E' }} fontSize={matchTablet ? '15px' : '20px'} color='#8D8D8D' bg='#F7F6F6' borderLeft={'none'} borderTop='none' borderRight={'none'} borderBottom={'5px solid #C8D5D1'} fontWeight='300' fontFamily={'Lato'} type='text' name='message' />
                                <FormErrorMessage>
                                {form.message===''?'message cannot be empty':''}
                            </FormErrorMessage>
                           </FormControl>
                            <Button isLoading={loading} onClick={submit} alignSelf={matchTablet?'':'end'} cursor={'pointer'} border={`1px solid ${loading?'#E6007E':'white'}`} _hover={{ bg: 'white', color: 'black', border: '1px solid black' }} mt={matchMobile ? '25px' : '0px'} mb={matchMobile ? '10px' : '0px'} transition={'0.5s'} bg={loading?'white':'black'} borderRadius={'10px'} w='200px' color={loading?'#E6007E':'white'} fontSize={matchMobile ? '15px' : '20px'} fontFamily={'Lato'} fontWeight={'600'} p={matchTablet ? '25px 35px' : '25px 44px'}>Send Message</Button>
                        </Flex>
                        {matchTablet &&
                            <Flex w='100%' alignItems={'center'} justifyContent={'space-between'} p='1rem'>
                                <Box pl='2rem'>
                                    <Flex alignItems={'center'} gap='7px'> <Image w='15px' src={mapPin} alt='' /> <Text m='0' fontWeight={'400'} fontFamily={'Lato'} fontSize={matchMobile ? '10px' : '15px'}>AlBahsa, Damascus, Syria</Text></Flex>
                                    <Flex gap='7px' alignItems={'center'}> <Image w='15px' src={phone} alt='' /> <Text m='7px 0' fontWeight={'400'} fontFamily={'Lato'} fontSize={matchMobile ? '10px' : '15px'}>+963 941 444 806</Text></Flex>
                                </Box>
                                <Box >
                                    <a rel="noreferrer" target="_blank" href='https://www.facebook.com/profile.php?id=100089102088429&mibextid=ZbWKwL'><Image m='2' w='15px' src={facebook} alt='' /></a>
                                    <a rel="noreferrer" target="_blank" href='https://instagram.com/sekkara2023?igshid=YmMyMTA2M2Y='><Image m='2' w='15px' src={instagram} alt='' /></a>
                                </Box>
                            </Flex>
                        }
                    </Flex>
                        </form>

                </Box>

            </Flex>
            <Footer />
        </Flex>
    )
}
export default ContactUs;