import CardSlider from "../components/category slider/CategorySlider";
import ContactUs from "../components/contactus/ContactUs";
import IntroSection from "../components/intro section/IntroSection";
import Header from "../components/layouts/Header";
import Instructions from "../components/instructions/Instructions";
const Home = () => {
    return (
        <>
            <Header />
            <IntroSection />
            <CardSlider />
            <Instructions />
            <ContactUs />
        </>
    )
}
export default Home;