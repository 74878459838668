import { Box, Flex, Image, Link, Text, useMediaQuery } from "@chakra-ui/react"
import logo from '../../assets/vectors/sekkara-logo.svg'
import menu from '../../assets/vectors/menu.svg'

import '../../styles/header.css'
import { activeLink, getMobileOperatingSystem } from "../../util/conditions"
import { useEffect, useState } from "react"
const Header = () => {
    activeLink();
    const [match850] = useMediaQuery(["(max-width: 850px)"])
    const [match500] = useMediaQuery(["(max-width: 500px)"])
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if (isOpen) document.body.classList.add("stop-scrolling");
        else document.body.classList.remove("stop-scrolling");
    }, [isOpen])
    return (
        <>
            <Flex mt={match500?'10px':'35px'} h='48px' paddingInline={match850 ? '2.5rem' : '6.5rem'} bg='#FFFFFF;' justifyContent='space-between' alignItems='center'>
                <Image w={match850 ? '80px' : '120px'} h='40px' src={logo} alt='' />
                {!match850 ? (
                    <Flex justifyContent={'space-around'} gap='60px' alignItems='center' color={'#402B6E'} fontWeight={'500'}>
                        <Link id='home' color={'#E6007E'} textDecoration={'none !important'} href="#home"><Box id='header-link' className="header-link" > <Text m={'0'}>Home</Text> </Box ></Link >
                        <Link color={'#402B6E'} textDecoration={'none !important'} href="#services"><Box className="header-link">  <Text m={'0'}>Services</Text> </Box></Link>
                        <Link color={'#402B6E'} textDecoration={'none !important'} href="#contactus"><Box className="header-link">  <Text m={'0'}>Contact Us</Text> </Box></Link>
                        <Link color={'#402B6E'} textDecoration={'none !important'} href="/privacyPolicy"><Box className="header-link">  <Text m={'0'}>Privacy Policy</Text> </Box></Link>

                    </Flex >
                ) : null}
                <Flex justifyContent='space-between' gap={'28px'} alignItems='center'>
                    {!match500 && <Box onClick={getMobileOperatingSystem} w='fit-content' cursor={'pointer'} transition='0.4s' border='1px solid white' _hover={{ bg: 'white', color: '#E6007E', border: '1px solid #E6007E' }} borderRadius={'14px'} bg='#E6007E' fontSize={'16px'} fontWeight='800' color={'white'} p='0.5rem' paddingBlock={'0.7rem'} paddingInline={'1.5rem'} fontFamily={'Lato'}>
                        Get Sekkara
                    </Box>}
                    {match850 && <Image filter={isOpen ? 'blur(2px)' : ''} onClick={() => setIsOpen(!isOpen)} cursor={'pointer'} src={menu} alt='' />}
                </Flex>
            </Flex >
            {match850 &&
                <>
                    <Box onClick={() => { setIsOpen(false) }} display={isOpen ? 'block' : 'none'} position={'absolute'} color={'blackAlpha.700'} zIndex={'1000'} top='-11px' right={'41.3px'} ><span style={{ fontSize: '40px',fontWeight:'300' }}>x</span></Box>
                    <Box transition={'all 700ms'} transform={isOpen ? 'translateX(0px)' : 'translateX(-1000px)'} zIndex={'1000'} w='100%' h='100%' position={'fixed'} bg='white'>
                        <Flex position='relative' top={'20%'} flexDirection={'column'} justifyContent={'center'} gap='60px' alignItems='center' color={'#402B6E'} fontWeight={'500'}>
                            <Link onClick={() => setIsOpen(false)} id='home' color={'#E6007E'} textDecoration={'none !important'} href="#home"><Box id='header-link' className="header-link" > <Text fontSize={'20px'} m={'0'}>Home</Text> </Box ></Link >
                            <Link onClick={() => setIsOpen(false)} color={'#402B6E'} textDecoration={'none !important'} href="#services"><Box className="header-link">  <Text fontSize={'20px'} m={'0'}>Services</Text> </Box></Link>
                            <Link onClick={() => setIsOpen(false)} color={'#402B6E'} textDecoration={'none !important'} href="#contactus"><Box className="header-link">  <Text fontSize={'20px'} m={'0'}>Contact Us</Text> </Box></Link>
                        </Flex >
                    </Box>
                </>
            }
        </>
    )
}
export default Header;