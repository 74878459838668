
export const transitionsBigScreen = () => {   
        let overflowInstall = false;
        let overflowBrowse = false;
        let overflowSavemoney = false;
    window.scroll({
        behavior: 'smooth'
    })
    window.addEventListener('scroll', (e) => {
     
        //install text Elements
        var In = document.getElementById('in');
        var st = document.getElementById('st');
        var all = document.getElementById('all');
        var installText = document.getElementById('install-text');
        var allMotion = document.getElementById('all-animate');

        //browse text Elements
        var br = document.getElementById('br');
        var ow = document.getElementById('ow');
        var se = document.getElementById('se');
        var browseText = document.getElementById('browse-text');
        var brMotion = document.getElementById('br-animate');
        var owMotion = document.getElementById('ow-animate');
        var seMotion = document.getElementById('se-animate');

        //save money text Elements
        var sa = document.getElementById('sa');
        var ve = document.getElementById('ve');
        var m = document.getElementById('M');
        var on = document.getElementById('on');
        var ey = document.getElementById('ey');
        var savemoneyText = document.getElementById('save-money-text');
        var saMotion = document.getElementById('sa-animate');
        var veMotion = document.getElementById('ve-animate');
        var mMotion = document.getElementById('m-animate');
        var onMotion = document.getElementById('on-animate');
        var eyMotion = document.getElementById('ey-animate');

        //phone Image Container (contains the three pages)
        var image = document.getElementById('image-instruction');
        var tabletImage = document.getElementById('tablet-image-instruction');

        /////////////////////////////////////////////////////////////////
        //make the container hidden before scrolling
        /////////////////////////////////////////////////////////////////

        //install
        if (document.documentElement.scrollTop < 1700) {
            In.style.transform = 'translateX(-300px)';
            In.style.opacity = '0';
            st.style.transform = 'translateX(-300px)';
            st.style.opacity = '0';
            all.style.transform = 'translateX(-300px)';
            all.style.opacity = '0';
            installText.style.transform = 'translateY(300px)';
            installText.style.opacity = '0';
            overflowInstall = false

        }

        //browse
        if (document.documentElement.scrollTop < 2400) {
            br.style.transform = 'translateX(-300px)';
            br.style.opacity = '0';
            ow.style.transform = 'translateX(-300px)';
            ow.style.opacity = '0';
            se.style.transform = 'translateX(-300px)';
            se.style.opacity = '0';
            browseText.style.transform = 'translateY(300px)';
            browseText.style.opacity = '0';
            overflowBrowse= false;

        }

        //savemoney
        if (document.documentElement.scrollTop < 3000) {
            sa.style.transform = 'translateX(-300px)';
            sa.style.opacity = '0';
            ve.style.transform = 'translateX(-300px)';
            ve.style.opacity = '0';
            m.style.transform = 'translateX(-300px)';
            m.style.opacity = '0';
            on.style.transform = 'translateX(-300px)';
            on.style.opacity = '0';
            ey.style.transform = 'translateX(-300px)';
            ey.style.opacity = '0';
            savemoneyText.style.transform = 'translateY(300px)';
            savemoneyText.style.opacity = '0';
            overflowSavemoney=false
        }

        /////////////////////////////////////////////////////////////////
        //make the container visible when arriving to the container
        /////////////////////////////////////////////////////////////////

        //install
        if (document.documentElement.scrollTop >= 1700 && document.documentElement.scrollTop <= 2400) {
            console.log(overflowInstall); 
            if (!overflowInstall) {document.body.style.overflow = 'hidden';}
            In.style.transform = 'translateX(0px)';
            In.style.opacity = '1';
            st.style.transform = 'translateX(0px)';
            st.style.opacity = '1';
            all.style.transform = 'translateX(0px)';
            all.style.opacity = '1';
            installText.style.transform = 'translateY(0px)';
            installText.style.opacity = '1';
            allMotion.style.color = '#E6007E';
            allMotion.style.animation = 'allAnimation 1.5s ease-in-out';
            allMotion.style.clipPath = 'polygon( 0% 20%, 16% 20%, 33% 20%, 54% 20%, 70% 20%, 84% 20%, 100% 20%, 100% 100%, 0% 100% )';
            setTimeout(() => {

                if (image) {
                    image.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                }
                else {
                    tabletImage.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                }
            }, 1000)
            setTimeout(() => { document.body.style.overflow = 'scroll'; }, 2000)
            overflowInstall = true
        }
        //browse
        if (document.documentElement.scrollTop >= 2400 && document.documentElement.scrollTop <= 3000) {
            if (!overflowBrowse) document.body.style.overflow = 'hidden';

            br.style.transform = 'translateX(0px)';
            br.style.opacity = '1';
            brMotion.style.color = '#E6007E';
            brMotion.style.animation = 'brAnimation 1.2s ease-in-out 0.5s';
            brMotion.style.animationFillMode = 'backwards';
            brMotion.style.clipPath = 'polygon(0% 85%, 16% 85%, 33% 85%, 54% 85%, 70% 85%, 84% 85%, 100% 85%, 100% 100%, 0% 100%)';
            ow.style.transform = 'translateX(0px)';
            ow.style.opacity = '1';
            owMotion.style.color = '#E6007E';
            owMotion.style.animation = 'owAnimation 1.2s ease-in-out 0.2s';
            owMotion.style.animationFillMode = 'backwards';
            se.style.transform = 'translateX(0px)';
            se.style.opacity = '1';
            seMotion.style.color = '#E6007E';
            browseText.style.transform = 'translateY(0px)';
            browseText.style.opacity = '1';
            setTimeout(() => {


                if (image) {
                    image.scrollTo({
                        top: 550,
                        behavior: "smooth",
                    });
                }
                else {
                    tabletImage.scrollTo({
                        top: 445,
                        behavior: "smooth",
                    });
                }
            }, 1000)

            setTimeout(() => { document.body.style.overflow = 'scroll'; }, 2000)
            overflowBrowse = true
        }

        //savemoney
        if (document.documentElement.scrollTop >= 3000) {
            if (!overflowSavemoney) document.body.style.overflow = 'hidden';

            sa.style.transform = 'translateX(0px)';
            sa.style.opacity = '1';
            saMotion.style.color = '#E6007E';
            saMotion.style.animation = 'saAnimation 1.2s ease-in-out 1.2s';
            saMotion.style.animationFillMode = 'backwards';
            ve.style.transform = 'translateX(0px)';
            ve.style.opacity = '1';
            veMotion.style.color = '#E6007E';
            veMotion.style.animation = 'veAnimation 1.2s ease-in-out 0.6s';
            veMotion.style.animationFillMode = 'backwards';
            m.style.transform = 'translateX(0px)';
            m.style.opacity = '1';
            mMotion.style.color = '#E6007E';
            mMotion.style.animation = 'mAnimation 1s ease-in-out';
            mMotion.style.animationFillMode = 'backwards';
            on.style.transform = 'translateX(0px)';
            on.style.opacity = '1';
            onMotion.style.color = '#E6007E';
            onMotion.style.animationFillMode = 'backwards';
            ey.style.transform = 'translateX(0px)';
            ey.style.opacity = '1';
            eyMotion.style.color = '#E6007E';
            eyMotion.style.animationFillMode = 'backwards';
            savemoneyText.style.transform = 'translateY(0px)';
            savemoneyText.style.opacity = '1';
            setTimeout(() => {

                if (image) {
                    image.scrollTo({
                        top: 1250,
                        behavior: "smooth",
                    });
                }
                else {
                    tabletImage.scrollTo({
                        top: 1150,
                        behavior: "smooth",
                    });
                }
            }, 1000)

            setTimeout(() => { document.body.style.overflow = 'scroll'; }, 2000)
            overflowSavemoney = true
        }

        ////////////////////////////////////////////////////////////////////
        //make the container hidden when scrolling down
        ////////////////////////////////////////////////////////////////////

        //install
        if (document.documentElement.scrollTop >= 2400) {
            In.style.transform = 'translateX(-300px)';
            In.style.opacity = '0';
            st.style.transform = 'translateX(-300px)';
            st.style.opacity = '0';
            all.style.transform = 'translateX(-300px)';
            all.style.opacity = '0';
            installText.style.transform = 'translateY(300px)';
            installText.style.opacity = '0';
            overflowInstall=false;

        }
        //browse
        if (document.documentElement.scrollTop >= 3000) {
            br.style.transform = 'translateX(-300px)';
            br.style.opacity = '0';
            ow.style.transform = 'translateX(-300px)';
            ow.style.opacity = '0';
            se.style.transform = 'translateX(-300px)';
            se.style.opacity = '0';
            browseText.style.transform = 'translateY(300px)';
            browseText.style.opacity = '0';
            overflowBrowse=false;
        }
        /////////////////////////////////////////////////////////////////////
    });
}

export const transitionResponsive = () => {
    let overflowInstall = false;
    let overflowBrowse = false;
    let overflowSavemoney = false;
    window.scroll({
        behavior: 'smooth'
    })
    window.addEventListener('scroll', (e) => {

        //install text
        var In = document.getElementById('in');
        var st = document.getElementById('st');
        var all = document.getElementById('all');
        var installText = document.getElementById('install-text');
        var allMotion = document.getElementById('all-animate');

        //browse text
        var br = document.getElementById('br');
        var ow = document.getElementById('ow');
        var se = document.getElementById('se');
        var browseText = document.getElementById('browse-text');
        var brMotion = document.getElementById('br-animate');
        var owMotion = document.getElementById('ow-animate');
        var seMotion = document.getElementById('se-animate');

        //save money text
        var sa = document.getElementById('sa');
        var ve = document.getElementById('ve');
        var m = document.getElementById('M');
        var on = document.getElementById('on');
        var ey = document.getElementById('ey');
        var savemoneyText = document.getElementById('save-money-text');
        var saMotion = document.getElementById('sa-animate');
        var veMotion = document.getElementById('ve-animate');
        var mMotion = document.getElementById('m-animate');
        var onMotion = document.getElementById('on-animate');
        var eyMotion = document.getElementById('ey-animate');

        //image
        var image = document.getElementById('image-instruction');
        var tabletImage = document.getElementById('tablet-image-instruction');

        /////////////////////////////////////////////////////////////////
        //make the container hidden before scrolling
        /////////////////////////////////////////////////////////////////

        //install
        if (document.documentElement.scrollTop < 900) {
            In.style.transform = 'translateX(-300px)';
            In.style.opacity = '0';
            st.style.transform = 'translateX(-300px)';
            st.style.opacity = '0';
            all.style.transform = 'translateX(-300px)';
            all.style.opacity = '0';
            installText.style.transform = 'translateY(300px)';
            installText.style.opacity = '0';
            overflowInstall = false

        }

        //browse
        if (document.documentElement.scrollTop < 1300) {
            br.style.transform = 'translateX(-300px)';
            br.style.opacity = '0';
            ow.style.transform = 'translateX(-300px)';
            ow.style.opacity = '0';
            se.style.transform = 'translateX(-300px)';
            se.style.opacity = '0';
            browseText.style.transform = 'translateY(300px)';
            browseText.style.opacity = '0';
            overflowBrowse = false
        }

        //savemoney
        if (document.documentElement.scrollTop < 1600) {
            sa.style.transform = 'translateX(-300px)';
            sa.style.opacity = '0';
            ve.style.transform = 'translateX(-300px)';
            ve.style.opacity = '0';
            m.style.transform = 'translateX(-300px)';
            m.style.opacity = '0';
            on.style.transform = 'translateX(-300px)';
            on.style.opacity = '0';
            ey.style.transform = 'translateX(-300px)';
            ey.style.opacity = '0';
            savemoneyText.style.transform = 'translateY(300px)';
            savemoneyText.style.opacity = '0';
            overflowSavemoney = false
        }

        /////////////////////////////////////////////////////////////////
        //make the container visible when arriving to the container
        /////////////////////////////////////////////////////////////////

        //install
        if (document.documentElement.scrollTop >= 900 && document.documentElement.scrollTop <= 1300) {

            if (!overflowInstall) document.body.style.overflow = 'hidden';
            In.style.transform = 'translateX(0px)';
            In.style.opacity = '1';
            st.style.transform = 'translateX(0px)';
            st.style.opacity = '1';
            all.style.transform = 'translateX(0px)';
            all.style.opacity = '1';
            installText.style.transform = 'translateY(0px)';
            installText.style.opacity = '1';
            allMotion.style.color = '#E6007E';
            allMotion.style.animation = 'allAnimation 1.5s ease-in-out';
            allMotion.style.clipPath = 'polygon( 0% 20%, 16% 20%, 33% 20%, 54% 20%, 70% 20%, 84% 20%, 100% 20%, 100% 100%, 0% 100% )';
            if (image) {
                image.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            }
            else {
                tabletImage.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            }
            setTimeout(() => { document.body.style.overflow = 'scroll'; }, 2000)
            overflowInstall = true
        }
        //browse
        if (document.documentElement.scrollTop >= 1300 && document.documentElement.scrollTop <= 1600) {
            if (!overflowBrowse) document.body.style.overflow = 'hidden';

            br.style.transform = 'translateX(0px)';
            br.style.opacity = '1';
            brMotion.style.color = '#E6007E';
            brMotion.style.animation = 'brAnimation 1.2s ease-in-out 0.5s';
            brMotion.style.animationFillMode = 'backwards';
            brMotion.style.clipPath = 'polygon(0% 85%, 16% 85%, 33% 85%, 54% 85%, 70% 85%, 84% 85%, 100% 85%, 100% 100%, 0% 100%)';
            ow.style.transform = 'translateX(0px)';
            ow.style.opacity = '1';
            owMotion.style.color = '#E6007E';
            owMotion.style.animation = 'owAnimation 1.2s ease-in-out 0.2s';
            owMotion.style.animationFillMode = 'backwards';
            se.style.transform = 'translateX(0px)';
            se.style.opacity = '1';
            seMotion.style.color = '#E6007E';
            browseText.style.transform = 'translateY(0px)';
            browseText.style.opacity = '1';

            if (image) {
                image.scrollTo({
                    top: 550,
                    behavior: "smooth",
                });
            }
            else {
                tabletImage.scrollTo({
                    top: 445,
                    behavior: "smooth",
                });
            }
            setTimeout(() => { document.body.style.overflow = 'scroll'; }, 2000)
            overflowBrowse = true
        }

        //savemoney
        if (document.documentElement.scrollTop >= 1600) {
            if (!overflowSavemoney) document.body.style.overflow = 'hidden';

            sa.style.transform = 'translateX(0px)';
            sa.style.opacity = '1';
            saMotion.style.color = '#E6007E';
            saMotion.style.animation = 'saAnimation 1.2s ease-in-out 1.2s';
            saMotion.style.animationFillMode = 'backwards';
            ve.style.transform = 'translateX(0px)';
            ve.style.opacity = '1';
            veMotion.style.color = '#E6007E';
            veMotion.style.animation = 'veAnimation 1.2s ease-in-out 0.6s';
            veMotion.style.animationFillMode = 'backwards';
            m.style.transform = 'translateX(0px)';
            m.style.opacity = '1';
            mMotion.style.color = '#E6007E';
            mMotion.style.animation = 'mAnimation 1s ease-in-out';
            mMotion.style.animationFillMode = 'backwards';
            on.style.transform = 'translateX(0px)';
            on.style.opacity = '1';
            onMotion.style.color = '#E6007E';
            onMotion.style.animationFillMode = 'backwards';
            ey.style.transform = 'translateX(0px)';
            ey.style.opacity = '1';
            eyMotion.style.color = '#E6007E';
            eyMotion.style.animationFillMode = 'backwards';
            savemoneyText.style.transform = 'translateY(0px)';
            savemoneyText.style.opacity = '1';

            if (image) {
                image.scrollTo({
                    top: 1250,
                    behavior: "smooth",
                });
            }
            else {
                tabletImage.scrollTo({
                    top: 1150,
                    behavior: "smooth",
                });
            }
            setTimeout(() => { document.body.style.overflow = 'scroll'; }, 2000)
            overflowSavemoney = true
        }

        ////////////////////////////////////////////////////////////////////
        //make the container hidden when scrolling down
        ////////////////////////////////////////////////////////////////////

        //install
        if (document.documentElement.scrollTop >= 1300) {
            In.style.transform = 'translateX(-300px)';
            In.style.opacity = '0';
            st.style.transform = 'translateX(-300px)';
            st.style.opacity = '0';
            all.style.transform = 'translateX(-300px)';
            all.style.opacity = '0';
            installText.style.transform = 'translateY(300px)';
            installText.style.opacity = '0';
            overflowInstall = false

        }
        //browse
        if (document.documentElement.scrollTop >= 1600) {
            br.style.transform = 'translateX(-300px)';
            br.style.opacity = '0';
            ow.style.transform = 'translateX(-300px)';
            ow.style.opacity = '0';
            se.style.transform = 'translateX(-300px)';
            se.style.opacity = '0';
            browseText.style.transform = 'translateY(300px)';
            browseText.style.opacity = '0';
            overflowBrowse = false
        }
    });
}

export const activeLink = () => {
    // window.addEventListener('scroll', function () {
    //     if (document.documentElement.scrollTop < 2000 && document.documentElement.scrollTop >= 0) {
    //         document.getElementById('home').style.color = '#E6007E';

    //     }
    //     else {
    //         document.getElementById('home').style.color = '#402B6E';
    //     }
    // });
}

export const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        window.location.href = "https://play.google.com/store/apps/details?id=com.user.sekkara";
    }

    if (/android/i.test(userAgent)) {
        window.location.href = "https://play.google.com/store/apps/details?id=com.user.sekkara";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.href = "https://apps.apple.com/us/app/sekkara/id6448356380";
    }
    if (/webOS/i.test(userAgent)) {
        window.location.href = "https://play.google.com/store/apps/details?id=com.user.sekkara";
    }

    window.location.href = "https://play.google.com/store/apps/details?id=com.user.sekkara";
}