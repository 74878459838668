const IconRight = ({ opacity = "0.66" }) => {
    return (
        <svg width="44" height="44" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
                <circle cx="34" cy="34" r="34" fill="white" />
                <circle cx="34" cy="34" r="33.25" stroke="#402B6E" strokeOpacity={opacity}strokeWidth="1.5" />
            </g>
            <path d="M29.1154 22.8846L40.2308 34L29.1154 45.1154" stroke="#402B6E" strokeOpacity={opacity} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IconRight;

