import { Box, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import mobile from '../../assets/vectors/mobile.svg'
import '../../styles/introSection.css'
import { Autoplay } from "swiper";
import appleStore from '../../assets/vectors/stores/Apple_Store.svg'
import googlePlayStore from '../../assets/vectors/stores/Google_Play_Store.svg'
import { logos } from "../../util/logosData";
const IntroSection = () => {
    const [match1300, match1180, match1100, match860, match680] = useMediaQuery(["(min-width: 1300px)", "(min-width: 1180px)", "(min-width: 1100px)", "(min-width: 860px)", "(min-width: 680px)"])
    const [match850] = useMediaQuery(["(max-width: 850px)"])
    const [matchMobile] = useMediaQuery(["(max-width: 680px)"])

    return (
        <Box paddingInline={matchMobile ? '1rem' : '2.3rem'} pt='1rem' mb={matchMobile ? '1rem' : '2rem'} >
            <Box display={'flex'} flexDirection='column' justifyContent={'space-between'} bg='#F7F6F6' w='100%' h={match1300 ? '820px' : match1180 ? '770px' : match1100 ? '680px' : match860 ? '600px' : match680 ? '570px' : matchMobile ? 'auto' : '570px'} borderRadius={'15px'}>
                <Box color={'#402B6E'}>
                    {!matchMobile ? (
                        <Flex justifyContent={'space-between'} alignItems={'baseline'} paddingInline={["1rem", "2rem", "4rem", "5rem", '7rem']} paddingTop={'3rem'}>

                            <Box position={'relative'} display={'flex'} flexDirection='column' gap={'20px'}>
                                <Box w='max-content' fontSize={["20px", "25px", "35px", "45px", "50px"]} fontWeight='700' fontFamily={'Albra Display TRIAL'}>The Sweetest Offer</Box>

                                <Box position={'absolute'} top={['40px', '50px', '60px', '70px', '80px']}>
                                    <Box w={match1300 ? '500px' : match1180 ? '450px' : match860 ? '400px' : match680 ? '250px' : '250px'} fontSize={["12px", "14px", "14px", "18px", "24px"]} fontWeight='400' lineHeight='30px' fontFamily={'Lato'}>
                                    Covering your favorite places all over. Aiming to make your experience most enjoyable with our offers, updated on a daily basis.                                    </Box>
                                    <Flex mt='20px' gap='30px' >
                                        <a href='https://apps.apple.com/us/app/sekkara/id6448356380' rel="noreferrer" target="_blank"><Image w={match1300?'150px': match850 ? '100px' : match680 ? '120px' : '150px'} src={appleStore} alt="" /></a>
                                        <a rel="noreferrer" href='https://play.google.com/store/apps/details?id=com.user.sekkara' target="_blank"> <Image w={match1300?'150px':match850 ? '100px' : match680 ? '120px' : '150px'} src={googlePlayStore} alt="" /></a>
                                    </Flex>
                                </Box>
                            </Box>


                            <Flex h='5px' w='100%' mt={'44px'} alignItems='center' justifyContent={'center'}>
                                <Box borderRadius={'50%'} w='8px' h={'8px'} bg='#402B6E'></Box>
                                <hr className="hr"></hr>
                                <Box borderRadius={'50%'} w='8px' h={'8px'} bg='#402B6E'></Box>
                            </Flex>


                            <Box w='70%' position={'relative'} top={'-45px'} fontSize={["20px", "30px", "35px", "40px", "55px"]} fontWeight='600' fontFamily={'Albra Display TRIAL'}>
                                <Box minW='254px' w='inherit' position={'absolute'}>
                                    <Box w='fit-content' h='fit-content'>
                                        <Text m='0'>All Over</Text>
                                        <Text opacity={'0.8'} m='0'>All Over</Text>
                                        <Text opacity={'0.6'} m='0'>All Over</Text>
                                        <Text opacity={'0.4'} m='0'>All Over</Text>
                                        <Text opacity={'0.2'} m='0'>All Over</Text>
                                    </Box>
                                    <Image maxW={'none'} w={match1300 ? '1000px' : match1180 ? '900px' : match1100 ? '750px' : match860 ? '600px' : match680 ? '500px' : '500px'} position={'relative'} top={match1300 ? '-350px' : match1180 ? '-260px' : match1100 ? '-250px' : match860 ? '-220px' : match680 ? '-200px' : '-200px'} transform={'translateX(-50%)'} left='0%' src={mobile} alt='' />
                                </Box>
                            </Box>

                        </Flex>
                    )
                        :
                        (
                            <Flex justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                                <Box mt='15px' w='max-content' fontSize={["30px", "25px", "35px", "45px", "50px"]} fontWeight='700' fontFamily={'Albra Display TRIAL'}>The Sweetest Offer.</Box>
                                <Box w='fit-content' position={'relative'} fontSize={["30px", "35px", "35px", "40px", "55px"]} lineHeight={'40px'} fontWeight='600' fontFamily={'Albra Display TRIAL'}>
                                    <Box mt='10px' minW='204px' w='inherit' textAlign={'-webkit-center'}>
                                        <Box w='fit-content' h='fit-content'>
                                            <Text m='0'>All Over</Text>
                                            <Text opacity={'0.8'} m='0'>All Over</Text>
                                            <Text opacity={'0.6'} m='0'>All Over</Text>
                                            <Text opacity={'0.4'} m='0'>All Over</Text>
                                            <Text opacity={'0.2'} m='0'>All Over</Text>
                                        </Box>
                                        <Image maxW={'none'} w={'450px'} position={'absolute'} top={'55px'} left='0%' transform={'translateX(-38%)'} src={mobile} alt='' />
                                    </Box>
                                </Box>
                                <Box>
                                    <Box w={'100%'} mt='6rem' fontSize={["12px", "14px", "16px", "18px", "20px"]} textAlign={'center'} paddingInline={'15px'} fontWeight='400' fontFamily={'Lato'}>
                                    Covering your favorite places all over. Aiming to make your experience most enjoyable with our offers, updated on a daily basis.                                    </Box>
                                    <Flex mt='5px' gap='15px' justifyContent={'center'} alignItems={'center'}>
                                        <a href='https://apps.apple.com/us/app/sekkara/id6448356380' rel="noreferrer" target="_blank"> <Image w='100px' h='60px' src={appleStore} alt="" /></a>
                                        <a href='https://play.google.com/store/apps/details?id=com.user.sekkara' rel="noreferrer" target="_blank"> <Image w='100px' h='60px' src={googlePlayStore} alt="" /></a>
                                    </Flex>
                                </Box>
                            </Flex>
                        )
                    }
                </Box>
                <Box pb={!matchMobile ? '6.3rem' : '1.5rem'} pt={!matchMobile ? '0px' : '1.5rem'}>
                    <Swiper speed={2500} autoplay={{ delay: 1, disableOnInteraction: false, }} loop={true} modules={[Autoplay]} style={{ textAlign: 'center' }} className="mySwiper" slidesPerView={!matchMobile ? 7.5 : 4.3}>
                        {logos.map((logo,index) => (
                            <SwiperSlide key={index}>
                                <Image w='65px' h={match850 ? '40px' : '75px'} src={logo.logo} alt='' />
                            </SwiperSlide>
                        ))}

                    </Swiper>
                </Box>

            </Box >
        </Box >
    )
}
export default IntroSection;