import { Box, Flex, Heading } from "@chakra-ui/react";
import { privacyPolicy } from "../util/privacyPolicyData";
const PrivacyPolicy = () => {
  return (
    <>
      <Flex
        direction="rtl"
        flexDirection={"column"}
        justifyContent="center"
        alignItems="center"
      >
        <Heading fontSize={"20px"} mb="20px">
          سياسة الخصوصية لتطبيق سكرَّة
        </Heading>
        <Box  marginBottom= "50px" textAlign='center'>
          شكرًا لاستخدامك إلى تطبيق سكرَّة-Sekkara الذي تديره شركة بان
          كود-Pan-code إنّ تطبيقنا يحترم خصوصيّتك ويسعى لحماية بياناتك الشخصية.
          لمعرفة المزيد، يُرجى قراءة سياسة الخصوصيّة أدناه. توضح سياسة الخصوصيّة
          كيفية جمع واستخدام بياناتك الشخصية (تحت ظروفٍ معينةٍ). كما تذكرُ أيضًا
          الإجراءات المتبعة لضمان خصوصية معلوماتك. وأخيرًا، تُحدّد هذه السياسة
          الخيارات المتاحة لكَ فيما يتعلَّق بجمع البيانات الشخصية واستخدامها
          والكشف عنها. ومن خلال زيارتك للتطبيق مباشرةً، فأنتَ توافق على
          الممارسات الموضحة في هذه السياسة. إن حماية بياناتك أمرٌ هامٌ جدًّا
          بالنسبة إلينا. ومن ثم، يتم استخدام اسمك وغيره من المعلومات التي تتعلّق
          بك وفقًا للّنحو المبيّن في سياسة الخصوصيّة. وسنقوم بجمع المعلومات عند
          الضرورة أو إذا كانت ذات صلةٍ ضرورية مباشرةٍ بمعاملاتنا معكَ. وسنقوم
          بالاحتفاظ ببياناتك وفقًا للقانون أو لاستخدامها للأغراض التي جُمعت
          لأجلها. ويمكنك تصفح التطبيق دون الحاجة إلى تقديم أيّة بيانات شخصيّة.
          وتبقى هويّتك الشخصية مجهولة طيلة زيارتك للتطبيق ولا يتم كشفها إلا إذا
          كنتَ تملك حسابًا إلكترونياً خاصًا على التطبيق تدخل إليه بواسطة اسم رقم
          الهاتف ورمز التأكيد لتفعيل الحساب.
        </Box>

        {privacyPolicy?.map((item) => (
          <>
            <Heading fontSize={"15px"} mb="20px">
              {item?.title}
            </Heading>
            <Box mb="20px" textAlign={"center"}>
              {item?.body}
            </Box>
          </>
        ))}
      </Flex>
    </>
  );
};

export default PrivacyPolicy;
