import { Box, Flex, Image, Text } from "@chakra-ui/react";
import pancode from '../../assets/images/PancodeLogo.png'
const Footer = () => {
    let date = new Date();
    let year = date.getFullYear();
    return (
        <Flex flexDirection={'column'} justifyContent={'center'} letterSpacing={'0.01rem'} color={'black'} fontSize={['12px','15px','16px','16px','16px']} fontFamily={'Lato'} fontWeight={'800'} alignItems='center' w='100%' bg='white' h='60px' p='3' >
            <Text>Sekkara © {year} . All Right Reserved</Text>
            <a rel="noreferrer" target="_blank" href="https://pan-code.com/">
            <Flex w='100%' justifyContent={'center'} gap={'5px'}>
            <Text fontWeight={'400'} mt='5px'>Powered By Pancode</Text>
            <Box w='20px' mt={['2px','2px','3px','5px','5px']}><Image w='100%' src={pancode} alt=''/></Box>
            </Flex>
            </a>
        </Flex>
    )
}
export default Footer;