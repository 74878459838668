import { Box, Heading, Text, useMediaQuery } from "@chakra-ui/react"
import InstructionCard from "./InstructionCard";
import '../../styles/instructions.css'
import { transitionResponsive, transitionsBigScreen } from "../../util/conditions";


const Instructions = () => {
    const [matchMobile425] = useMediaQuery(["(max-width: 425px)"])
    const [matchMobile, matchTablet, matchLaptop] = useMediaQuery(["(min-width: 280px)", "(min-width: 425px)", "(min-width: 768px)"])

    if (matchLaptop) transitionsBigScreen();
    else if (matchTablet || matchMobile) transitionResponsive();

    return (
        <>
            <Box id="services" bg='#402B6E' w='100%' minH={matchLaptop ? '400vh' : matchTablet ? '300vh' : '300vh'} position={'relative'}>

                <InstructionCard
                    Heading={
                        <>

                            <Box position={'absolute'} top={matchMobile425 ? '200px' : '200px'} id='in' className="in" ><Heading m='0' lineHeight={'170px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>In</Heading></Box>
                            <Box position={'absolute'} top={matchMobile425 ? '300px' : '350px'} id='st' className="st" ><Heading m='0' lineHeight={'170px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>st</Heading></Box>
                            <Box position={'absolute'} top={matchMobile425 ? '400px' : '530px'} id='all' className="all" >
                                <Heading m='0' lineHeight={'170px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>all</Heading>
                                <Heading id='all-animate' m='0' lineHeight={'170px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>all</Heading>
                            </Box>

                            <Box position={'absolute'} top={matchMobile425 ? '250px' : '300px'} id='br' className="br" >
                                <Heading m='0' lineHeight={'120px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>Br</Heading>
                                <Heading id='br-animate' m='0' lineHeight={'120px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>Br</Heading>
                            </Box>
                            <Box position={'absolute'} top={matchMobile425 ? '350px' : '430px'} id='ow' className="ow" >
                                <Heading m='0' lineHeight={'120px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>ow</Heading>
                                <Heading id='ow-animate' m='0' lineHeight={'120px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>ow</Heading>
                            </Box>
                            <Box position={'absolute'} top={matchMobile425 ? '450px' : '560px'} id='se' className="se" >
                                <Heading m='0' lineHeight={'120px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>se</Heading>
                                <Heading id='se-animate' m='0' lineHeight={'120px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>se</Heading>
                            </Box>

                            <Box position={'absolute'} top={matchMobile425 ? '50px' : '0px'} id='sa' className="sa" >
                                <Heading m='0' mt={matchMobile425 ? '' : '40px'} lineHeight={'120px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>Sa</Heading>
                                <Heading m='0' mt={matchMobile425 ? '' : '40px'} id='sa-animate' lineHeight={'120px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>Sa</Heading>
                            </Box>
                            <Box position={'absolute'} top={matchMobile425 ? '150px' : '120px'} id='ve' className="ve" >
                                <Heading m='0' mt={matchMobile425 ? '' : '40px'} lineHeight={'120px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>ve</Heading>
                                <Heading m='0' mt={matchMobile425 ? '' : '40px'} id='ve-animate' lineHeight={'120px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>ve</Heading>
                            </Box>
                            <Box position={'absolute'} top={matchMobile425 ? '250px' : '280px'} id='M' className="m" >
                                <Heading m='0' mt={matchMobile425 ? '' : '40px'} lineHeight={'120px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>M</Heading>
                                <Heading m='0' mt={matchMobile425 ? '' : '40px'} id='m-animate' lineHeight={'120px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>M</Heading>
                            </Box>
                            <Box position={'absolute'} top={matchMobile425 ? '350px' : '400px'} id='on' className="on" >
                                <Heading m='0' mt={matchMobile425 ? '' : '40px'} lineHeight={'120px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>on</Heading>
                                <Heading m='0' mt={matchMobile425 ? '' : '40px'} id='on-animate' lineHeight={'120px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>on</Heading>
                            </Box>
                            <Box position={'absolute'} top={matchMobile425 ? '450px' : '500px'} id='ey' className="ey" >
                                <Heading m='0' mt={matchMobile425 ? '' : '40px'} lineHeight={matchMobile425 ? '120px' : '200px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>ey</Heading>
                                <Heading m='0' mt={matchMobile425 ? '' : '40px'} id='ey-animate' lineHeight={matchMobile425 ? '120px' : '200px'} fontSize={matchMobile425 ? '100px' : ['80px', '120px', '120px', '140px', '160px']} fontFamily={'Albra Display TRIAL'}>ey</Heading>
                            </Box>

                        </>
                    }
                    text={
                        <>
                            <Box position={'relative'} w='100%'>
                                <Text id='install-text' position={'relative'} top={matchLaptop ? '500px' : '-10px'} m='0' fontSize={'20px'} color='white' fontFamily={'Lato'} fontWeight={'400'}>Sekkara from Play Store or App Store & Create a free account to enjoy 1 free month subscription.</Text>
                                <Text id='browse-text' m='0' top={matchLaptop ? '500px' : '-10px'} position={'absolute'} fontFamily={'Lato'} fontWeight={'400'} fontSize={'20px'} color='white'>From dozens of shops, restaurants, gyms, and many more all around you.</Text>
                                <Text id='save-money-text' m='0' top={matchLaptop ? '500px' : '0px'} position={'absolute'} fontSize={'20px'} color='white' fontFamily={'Lato'} fontWeight={'400'}>Using the discounts coupons updated everyday.</Text>

                            </Box>
                        </>
                    }
                />

            </Box>
        </>
    )
}
export default Instructions