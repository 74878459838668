import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import PreLoader from "./components/PreLoader";
import { useEffect, useState } from "react";
import PrivacyPolicy from "./pages/PrivacyPolicy";
// import { Helmet } from "react-helmet";
// import SEO from "./components/SEO";
function App() {
  const [load, setLoad] = useState(true);
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    const timer = setTimeout(() => {
      document.body.style.overflowY = "auto";
      setLoad(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {/* <Helmet>
        <SEO />
      </Helmet> */}
      <Router>
        <PreLoader load={load} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
