import kazamiza from '../assets/vectors/application logos/kazamiza.svg'
import actionPizza from '../assets/vectors/application logos/action-pizza.svg'
import antom from '../assets/vectors/application logos/antom.svg'
import betAlMkhtar from '../assets/vectors/application logos/bet-al-mkhtar.svg'
import climax from '../assets/vectors/application logos/climax.svg'
import flowersStation from '../assets/vectors/application logos/flowers-station.svg'
import fornino from '../assets/vectors/application logos/fornino.svg'
import fzlke from '../assets/vectors/application logos/fzlke.svg'
import gym24 from '../assets/vectors/application logos/gym24.svg'
import laCasaDeTacos from '../assets/vectors/application logos/la-casa-de-tacos.svg'
import mammoth from '../assets/vectors/application logos/mammoth.svg'
import mouyadSaad from '../assets/vectors/application logos/mouyad-saad.svg'
import next from '../assets/vectors/application logos/next.svg'
import onada from '../assets/vectors/application logos/onada.svg'
import seventies from '../assets/vectors/application logos/seventies.svg'
import shapeItUp from '../assets/vectors/application logos/shape-it-up.svg'
import traces from '../assets/vectors/application logos/traces.svg'
import unique from '../assets/vectors/application logos/unique.svg'

export let logos = [
    {
        logo: kazamiza,
    },
    {
        logo: actionPizza,
    },
    {
        logo: antom,
    },
    {
        logo: betAlMkhtar,
    },
    {
        logo: climax,
    },
    {
        logo: flowersStation,
    },
    {
        logo: fornino,
    },
    {
        logo: fzlke,
    },
    {
        logo: gym24,
    },
    {
        logo: laCasaDeTacos,
    },
    {
        logo: mammoth,
    },
    {
        logo: mouyadSaad,
    },
    {
        logo: next,
    },
    {
        logo: onada,
    },
    {
        logo: seventies,
    },
    {
        logo: shapeItUp,
    },
    {
        logo: traces,
    },
    {
        logo: unique,
    },
]