import { Box, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";

import bestfood from '../../assets/images/categories/bestfood.jpg';
import gym from '../../assets/images/categories/gym.jpg';
import health from '../../assets/images/categories/health.jpg';
import categories from '../../assets/images/categories/categories.png';

import food from '../../assets/vectors/categories/Food.svg';
import sport from '../../assets/vectors/categories/Sports.svg';
import face from '../../assets/vectors/categories/face.svg';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import '../../styles/categorySlider.css';

import { Navigation, Pagination, Mousewheel, Autoplay } from "swiper";
import { useCallback, useState } from "react";
import IconLeft from "./IconLeft";
import IconRight from "./IconRight";
const CategorySlider = () => {
    const [swiperRef, setSwiperRef] = useState();
    const [prev, setPrev] = useState(true)
    const [next, setNext] = useState(false)
    const [matchMobile] = useMediaQuery(["(max-width: 680px)"])


    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
        setPrev(swiperRef.isBeginning)
        setNext(swiperRef.isEnd)
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
        setNext(swiperRef.isEnd)
        setPrev(swiperRef.isBeginning)

    }, [swiperRef]);

    return (
        <Flex justifyContent={'center'} alignItems={'center'} h={'fit-content'} mb='2rem' position={'relative'} bottom={matchMobile ? '0px' : '70px'}>

            {!matchMobile &&
                <Box cursor={'pointer'} onClick={handlePrevious} position={'relative'} bottom={'40px'}>
                    <IconLeft opacity={'0.66'} />
                </Box>
            }

            <Box filter={'drop-shadow(0px 0px 13px rgba(0, 0, 0, 0.25))'} paddingInline={matchMobile ? '0px' : '32px'} w={matchMobile ? '100%' : '78%'} position={'relative'} >

                <Swiper
                    onSwiper={setSwiperRef}
                    cssMode={true}
                    pagination={{ clickable: true }}
                    mousewheel={true}
                    autoplay={{delay:4000,disableOnInteraction:true}}
                    modules={[Navigation, Pagination, Mousewheel,Autoplay]}
                    className="mySwiper"
                    style={{ borderRadius: matchMobile ? '0px' : '25px' }}
                    loop={true}
                >
                    <SwiperSlide style={{ position: 'relative !important' }}>
                        <Flex alignItems={'center'} position={'absolute'} top='0' left='0' bottom='0' right='0' bg='linear-gradient(90deg, #B8336A 0%, rgba(184, 51, 106, 0) 56.97%);'>
                            <Box w='100%' paddingInline={matchMobile ? '20px' : '70px'} >
                                <Image w={['50px','60px','70px','80px','90px']} h={['50px','60px','60px','80px','90px']} src={food} alt='' />
                                <Text fontWeight={'600'} mb={matchMobile ? '-7px' : '0px'} fontSize={['30px', '30px', '30px', '52px']} color={'white'} fontFamily={'Albra Display TRIAL'} > Same Food  </Text>
                                <Text fontWeight={'600'} m='0' fontSize={['30px', '30px', '30px', '52px']} color={'white'} fontFamily={'Albra Display TRIAL'} > Better Price </Text>
                                <Text w={matchMobile ? '20rem' : 'auto'} color={'white'} fontSize={['12px', '14px', '17x', '20px']} > You can find your favourite restaurent on Sekkara under Food category and get up to 30% discount. </Text>
                            </Box>
                        </Flex>
                        <Image w={'100%'} h='100%' src={bestfood} alt='' />
                    </SwiperSlide>

                    <SwiperSlide style={{ position: 'relative !important' }}>
                        <Box bg=' rgba(0, 0, 0, 0.17)' position={'absolute'} top='0' left='0' bottom='0' right='0'>

                        </Box>
                        <Flex alignItems={'center'} position={'absolute'} top='0' left='0' bottom='0' right='0' bg='linear-gradient(90deg, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0) 100%);'>
                            <Box w='100%' paddingInline={matchMobile ? '20px' : '70px'} >
                                <Image  w={['50px','60px','70px','80px','90px']} h={['50px','60px','60px','80px','90px']} src={sport} alt='' />
                                <Text fontWeight={'600'} mb={matchMobile ? '-7px' : '0px'} fontSize={['30px', '30px', '30px', '52px']} color={'white'} fontFamily={'Albra Display TRIAL'} > Healthy Body  </Text>
                                <Text fontWeight={'600'} m='0' fontSize={['30px', '30px', '30px', '52px']} color={'white'} fontFamily={'Albra Display TRIAL'} > Healthy Pocket. </Text>
                                <Text w={matchMobile ? '20rem' : 'auto'} color={'white'} fontSize={['12px', '14px', '17px', '20px']}>You can find your favourite gyms on Sekkara under Sports category and get up to 30% discount on your monthly subscription. </Text>
                            </Box>
                        </Flex>

                        <Image w={'100%'} h='100%' src={gym} alt='' />

                    </SwiperSlide>

                    <SwiperSlide style={{ position: 'relative !important' }}>
                        <Box bg=' rgba(0, 0, 0, 0.17)' position={'absolute'} top='0' left='0' bottom='0' right='0'>

                        </Box>
                        <Flex alignItems={'center'} position={'absolute'} top='0' left='0' bottom='0' right='0' bg='linear-gradient(90deg, rgba(184, 51, 106, 0.87) 0%, rgba(184, 51, 106, 0) 56.97%);'>
                            <Box w='100%' paddingInline={matchMobile ? '20px' : '70px'} >
                                <Image  w={['50px','60px','70px','80px','90px']} h={['50px','60px','60px','80px','90px']} src={face} alt='' />
                                <Text fontWeight={'700'} w='26rem' m='0' fontSize={['30px', '30px', '30px', '52px']} color={'white'} lineHeight={'55px'} fontFamily={'Albra Display TRIAL'} > Health & Wealth</Text>
                                <Text w={matchMobile ? '20rem' : 'auto'} color={'white'} fontSize={['12px', '18px', '20px', '25px']}>Beauty centers & clinic are waiting for you to provide you with the care you need with the sweetest offers. </Text>
                            </Box>
                        </Flex>

                        <Image w={'100%'} h='100%' src={health} alt='' />

                    </SwiperSlide>

                    <SwiperSlide style={{ position: 'relative !important' }}>
                        <Image w={'100%'} h='100%' src={categories} alt='' />
                    </SwiperSlide>
                </Swiper>
            </Box>
            {!matchMobile &&
                <Box cursor={'pointer'} onClick={handleNext} position={'relative'} bottom='40px'>
                    <IconRight opacity={'0.66'} />
                </Box>
            }
        </Flex>
    )
}
export default CategorySlider;